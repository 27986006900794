@import "sass-lib";

@media (min-width: 0px) and (max-width: 1620px) {
  .box-tags .drop-tags .in-drop-tags {
    max-height: 550px;
    width: 85%;
  }
}
@media (min-width: 0px) and (max-width: 1480px) {
	h1{font-size: 50px;}
	.wrapper{
		width: 100%;@include boxSizing(border-box);padding: 0 60px;
		&.box-sideshare{padding: 0 80px;}
	}
	
	.box-slider-home{
		.left{
			width: 100%;@include boxSizing(border-box);padding: 0 60px;top:300px;
			h1{line-height: 1.2;}
		}

		.arrow-slidehome{width: auto;margin: 0;left: auto;right: 60px;}
	}
	.highlight{
		.sliderwp{left: 50px;}
	}
	.box-news{
		> .left{width: 42%;}
		> .right{width: 55%;}
	}
	.box-tag{
		padding-left: 40px;padding-right: 40px;
		.tags{
			a{
				&:nth-child(n+6){display: none;}
			}
		}
	}
	.box-popular-news{
		> .left{width: 25%;}
		> .right{width: 72%;}
	}
	.in-box-news{
		> .left{width: 60%;}
		> .right{width: 36%;}
		.potrait-th{padding-bottom: 15px;margin-bottom: 20px;}
	}
	.box-tags{
		.drop-tags{
			.in-drop-tags{width: 85%;@include boxSizing(border-box);margin: 0 auto;}
		}
	}
	.box-search-nav{
		margin-left: 40px;
		&:before{left:-20px;bottom: -32px;}
		input[type=text]{width: 200px;font-size: 14px;}
	}
	.box-subnav{
		.linev{margin: 0 18px;height: 20px;}
	}
	.box-sideshare{
		.share-side{margin-left:-60px;}
	}
	.error-page{
		.in-error-page{
			img{width: 520px;max-width: 100%;}
			h4{font-size: 19px;}
			a{font-size: 18px;}
		}
	}
	.contentwp{
		h6{font-size: 26px;line-height: 40px;}
		blockquote{
			padding-left: 60px;padding-right: 60px;
			&:before{left:60px;}
			&:after{right:60px;}
		}
	}
}

@media (min-width: 0px) and (max-width: 1200px) {
	h2{font-size: 30px;}
	h4{
		&.tlight{font-size: 18px;}
	}
	
	.content-home{padding: 65px 0 80px;}
	.wrapper{
		&.box-sideshare{padding: 0 60px;}
	}

	header{
		padding-top: 35px;
		.box-logo{
			.logo{
				margin-right: 50px;
				&:before{right:-25px;}
				img{width: 230px;}
			}
			.tx-logo{font-size: 19px;padding-top: 5px;}
		}
		.nav-right{
			.box-search{margin-left: 20px;}
		}
		&.bgdark{height: 100px;}
		&.float{
			height: 70px;
			.nav-right{padding-top: 0;}
		}
	}

	.paddheadermoretop{padding-top: 150px;}
	.paddsecbot{padding-bottom: 50px;}
	.paddheadertop{padding-top: 100px;}

	.sliderhome{
		.list{
			.btn-play{width: 60px;height: 60px;background-size: 100%;}
		}
	}

	.box-lang{
		> span{font-size: 13px;}
		.drop-lang{
			a{font-size: 12px;}
		}
	}

	.box-search{
		.drop-search{
			input[type=text]{font-size: 13px;}
		}
	}

	.box-slider-home{
		.left{
			top:275px;
			h1{font-size: 36px;line-height: 52px;}
			a{font-size: 16px;}
			.in-text-slidehome{width: 280px;}
		}
	}
	.box-news{
		position: relative;
		> .left{
			position: absolute;top: 0;left: 0;bottom: 0;width: 45%;
			h2{margin-bottom: 20px;}
			a{font-size: 16px;}
			figure{
				position: static;
				img{object-fit: cover;width: 100%;height: 100%;position: absolute;top: 0;left: 0;bottom: 0;right:0;}
			}
			.text{padding: 0 40px 40px;}
		}
		> .right{padding-top: 30px;padding-bottom: 20px;width: 50%;}
	}
	.potrait-th{
		&.side-video{
			figure{
				&:after{width:40px;height: 40px;}
			}
		}
	}
	.in-box-news{
		.potrait-th{
			padding-bottom: 0;margin-bottom: 14px;border-bottom: none;
			figure{width: 30%;float: left;margin-bottom: 0;}
			.text{
				width: 65%;float: right;padding: 0;
				.tblue{margin-bottom: 5px;}
				p{font-size: 15px;line-height: 24px;height: 48px;}
			}
			&.tx-only{
				figure{display: block;}
			}
		}

		> .left,> .right{width: 100%;}
		> .left{
			.box-th{display: none;}
		}
	}
	.side-th{
		margin-bottom: 14px;
		figure{width: 30%;}
		.text{
			width: 65%;padding-top: 0;
			.tblue{margin-bottom: 5px;}
		}
	}
	.tags{
		a{margin: 0 5px 10px;padding: 0 25px;}
	}
	.box-popular-news{
		position: relative;
		> .left{
			position: absolute;top: 0;left: 0;bottom: 0;width: 40%;
			figure{
				position: static;
				img{width: 100%;height: 100%;object-fit: cover;object-position: top;position: absolute;top: 0;left: 0;bottom: 0;right: 0;}
			}
			.text{padding-bottom: 30px;}
		}
		> .right{width: 55%;padding: 30px 0;}
	}
	.slidepopular{
		.list{
			.btn-play{width: 60px;height: 60px;}
			.desc{
				p{font-size: 16px;line-height: 24px;height: 48px;}
			}
		}
	}
	.highlight{padding-top: 90px;}
	.top-highlight{margin-bottom: 20px;}
	.sliderwp{
		height: 315px;
		.inner-sliderwp{
			.list{
				figure{width: 430px;}
				.desc{
					h4{font-size: 16px;line-height: 24px;max-height: 48px;}
				}
				.btn-play{width: 60px;height: 60px;background-size: 100%;bottom: 50px;}
			}
		}
	}
	.box-thumb{
		margin: 0 -12px 20px;
		.ls-thumb{
			width: 50%;padding: 0 12px;margin-bottom: 30px;
			&:nth-child(3n+1){clear: inherit;}
			&:nth-child(2n+1){clear: both;}
		}
	}
	.box-subnav{
		.nav-left{padding: 30px 0 0;}
	}
	.subnav{
		a{font-size: 14px;padding: 0 10px;}
	}
	.box-sideshare{
		.share-side{margin-left: 0;position: relative;}
	}
	.share-side{
		@include afterboth;
		label{float: left;margin-right: 20px;line-height: 28px;}
		a{
			float: left;margin-right: 12px;
			&:last-child{margin-right:0;}
		}
	}
	.sidearticle{
		article,aside{width: 100%;}
		article{margin-bottom: 40px;}
		aside{
			.box-rounded{
				width: 48%;float: left;
				&:nth-child(even){float: right;}
			}
		}
	}

	.contentwp{
		h3{font-size: 26px;line-height: 34px;}
		h4{line-height: 34px;}
	}
	.banner-detail{
		h3{font-size: 26px;line-height: 34px;}
		.text{left: 40px;right: 40px;bottom: 30px;}
	}
	.box-tags{
		.btn-tags{font-size: 14px;}
		// .drop-tags{top:178px;}
	}
	.select-custom{
		.btn-select{font-size: 14px;}
	}
}

@media (max-width: 1366px) {
  .box-tags .drop-tags .in-drop-tags {
    max-height: 440px;
  }
}